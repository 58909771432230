import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/Hero"
import SectionTitle from "../components/SectionTitle"
import ContactForm from "../components/contact/ContactForm"

const ContactPage = ({ data }) => {
  return (
    <Layout>
      <SEO title="Contact" />
      <Hero syllabics="3lPuddhlaOflaPdg" englishTitle="Contact Us" />
      <div className="flex justify-center">
        <div className="mx-5 mt-12 px-8 leading-8 ">
          <div>
            <h2 className="font-bold mb-0">Kiiwetinoong Diabetes Initiative</h2>
            <div className="pl-2 mt-2">
              <p>
                c/o&nbsp;
                <a
                  href="https://www.shibogama.on.ca"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="font-bold underline  hover:font-normal"
                >
                  Shibogama First Nations Council
                </a>
                <br /> 81 King St., P.O. Box 449
                <br /> Sioux Lookout, ON P8T 1A5
              </p>
              <p>Phone: 807-737-2662</p>
              <p> Fax: 807-737-1583</p>
            </div>
          </div>
        </div>
      </div>
      <SectionTitle className="">Reach out!</SectionTitle>
      {/* Contact form goes here */}
      <div className="grid justify-items-center mb-10">
        <div className="w-full max-w-4xl ">
          <ContactForm />
        </div>
      </div>
    </Layout>
  )
}

export default ContactPage

// Sort grapql by multiple parameters https://stephencharlesweiss.com/graphql-multi-field-sorting/

export const query = graphql`
  query ContactUs {
    allSanityStaff(sort: { fields: [weight, name], order: [ASC] }) {
      edges {
        node {
          ...StaffCard
          ...HeadshotImage
        }
      }
    }
  }
`
